.inspection-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: var(--secondary-two);
  text-align: left;
  margin-bottom: 0px;
}

.inspection-question {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: #180e31;
  margin-bottom: -6px;
}

.inspection-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container-type-inspect {
  width: 270px;
  height: 151px;
  border-radius: 8px;
  border: 1px solid var(--border-cards);
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
}

.appointment-text {
  color: gray;
  font-size: 14px;
  margin-bottom: 0;
}


.change-insp-text {
  color: #31156d;
}