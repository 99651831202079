/* From uiverse.io by @Jedi-hongbin */
.button-back {
  display: flex;
  color: var(--primary);
  border-radius: 3px;
  letter-spacing: 1px;
  cursor: pointer;
  border: none;
  background: transparent;
  width: 311px;

}

.button-back:hover {
  color: var(--secondary);
}