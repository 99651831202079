$primary: #864bfa;
$dark: #31156d;

$theme-colors: (
  "primary": $primary,
  "dark": $dark,
);
@import "~bootstrap/scss/bootstrap";

:root {
  --primary: #864bfa;
  --secondary: #31156d;
  --secondary-two: #5f35bc;
  --secondary-light: #d9c4ff;
  --secondary-ultra-light: #f7f2ff;

  // ------------------------------ ROLES COLORS ---------------------

  --cs: #f1bc00;
  --as: #f86729;
  --backoffice: #9747ff;
  --inspector: #013db1;
  --pricing: #0e983d;
  --admin: #180e31;
  --system: #c5c8cc;

  // ------------------------------- STATES --------------------------------

  --blue: #238ee8;
  --blue-hover: #1162a6;
  --red: #f40000;
  --red-hover: #aa0000;
  --yellow: #ffc90a;
  --yellow-hover: #cc9f00;
  --green: #40c734;
  --green-hover: #229b18;

  // ------------------------------- OTHERS -------------------------------

  --background-cards: #ffffff;
  --border-cards: #e8e1ef;
  --background-dashboard: #f1f2f8;
  --mode-read-inputs: #818181;
  --disabled-elements: #c5c8cc;
  --text-side-bar: #26282c;
  --background-side-bar: #ede4ff;
}
