.add-card-title-modal {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
}

.add-card-text-body-modal {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #1b2130;
}

.rdp-months {
  display: flex;
  justify-content: center;
}

.text-content-table-deals {
  align-items: center;
  justify-content: center;
  color: #180e31;
  font-weight: 400;
  font-size: 12px;
  border-bottom-color: var(--secondary-ultra-light);
  text-transform: capitalize;
  margin-bottom: 0px;
  margin-top: 10px;
}

.deals-table-style {
  background-color: var(--background-cards);
  border-radius: 8px;
  height: 100%;
}

.container-content-header {
  overflow-x: scroll;
}

.table-container-grab {
  overflow: scroll;
  overflow-y: hidden;
  cursor: grab; /* Establece el cursor como "grab" por defecto */
}

.table-container-grab:active {
  cursor: grabbing; /* Cambia el cursor a "grabbing" cuando se mantiene presionado */
}

.text-length-action {
  margin: 0px;
  color: var(--primary);
  margin-top: 5px;
  margin-left: 5px;
}

.icon-bonus {
  width: 22px;
  height: 22px;
}
