.section-title {
  color: var(--secondary-two);
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: "SF Pro Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.section-input {
  border-radius: 8px;
  border: 2px solid var(--border-cards);
  background: var(--White, #FFF);
  padding: 8px 10px;
  color: var(--primary);
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
  width: 100%;
}

.section-input:focus-visible {
  border: 2px solid var(--secondary-light);
}

.section-input:disabled {
  background: #f8f8f8
}

.section-input-label {
  color: var(--secondary);
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
}

.section-container {
  border-radius: 8px;
  border: 2px solid var(--border-cards);
  padding: 10px;
}

.section-table {
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  border-width: 2px;
  width: 100%;
}

.section-table-cell {
  border: 2px solid var(--Card-Stroke, #E8E1EF);
  padding: 10px;
  text-align: center;
}

.section-table th,
.section-table td {
  color: var(--Ultra-Dark-Purple, #180E31);
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nav-tabs .nav-link {
  color: var(--secondary);
}

.nav-tabs .nav-link.active {
  border-color: transparent;
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
}

.nav-tabs .nav-link.active {
  border-color: transparent;
  border-bottom: 2px solid var(--primary);
}

.nav-tabs .nav-link:hover {
  border-top-color: #f8f8f8;
  border-left-color: #f8f8f8;
  border-right-color: #f8f8f8;
  background-color: #f8f8f8;
}

.overflow-x-auto {
  overflow-x: auto;
}

.section-checks input[type="checkbox"] {
  border: 2px solid #864BFA;
}

.section-radio input[type="radio"] {
  border: 2px solid #864BFA;
}

.section-info-icon {
  color: #864BFA;
  font-size: 18px;
  margin-left: 5px;
}

.key-img {
  margin-right: 8px;
  width: 25px;
  height: 25px;
}
