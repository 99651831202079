.item-list-img {
  list-style: none;
  color: var(--primary);
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
}

.attach-icon {
  font-size: 24px;
  color: var(--primary);
  cursor: pointer;
}

.attach-icon-text {
  color: var(--primary);
  cursor: pointer;
  font-size: 16px;
}

.progress {
  width: 30%;
  height: 8px;
}

.attch-input {
  margin-top: 30px;
}

.attach-text-kb {
  font-size: 14px;
  padding-right: 10px;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.delete-attach-icon {
  cursor: pointer;
  font-size: 16px;
  color: red;
}

.delete-photo {
  cursor: pointer;
  font-size: 28px;
}

.selected {
  border: 2px solid var(--primary);
  border-radius: 6px;
}

.grid-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: #5f35bc;
}
.grid-image-zoom {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: #5f35bc;
}

.img-carousel {
  width: 100%;
  /* height: 100%; */
  height: 90vh;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.grid-image-zoom.zoomed {
  cursor: zoom-out;
}

.grid-video {
  width: 100%;
  height: 100%;
  position: relative;
}

.video {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.video-fc {
  margin-left: 5%;
  width: 90%;
  height: 90vh;
}

.img-container {
  width: 120px;
  height: 100px;
}

.grid-image-zoom-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.zoom-controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
  margin-left: 35px;
}

.zoom-controls button {
  background-color: #5f35bc;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.zoom-controls button:disabled {
  background-color: grey;
  cursor: not-allowed;
}
