.reminder-input {
  width: 280px;
  height: 40px;
  border: 1px solid var(--border-cards);
  border-radius: 8px;
  outline: none;
  padding-left: 10px;
}

.reminder-input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--disabled-elements);
}

.reminder-text-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--disabled-elements);
  margin: 0;
}



@media screen and (max-width: 420px) {
  .inputCustom::placeholder {
    font-size: 10px;
  }

  .reminder-input::placeholder {
    font-size: 12px;
  }

  .reminder-text-placeholder {
    font-size: 12px;
  }

  .width-modal {
    width: 100vw;
  }
}