.chat-container {
  background-color: var(--background-cards);
  height: 66vh;
  border-radius: 8px;
  border: 1px solid var(--border-cards);
}

.chat-box {
  overflow: scroll;
  overflow-x: hidden;
}

.chat-input-send {
  outline: none;
  border: none;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
}

.scrollChat::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollChat::-webkit-scrollbar:vertical {
  width: 11px;
}

.scrollChat::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.scrollChat::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scrollChat::-webkit-scrollbar-thumb {
  background-color: var(--secondary-two);
  border-radius: 10px;
  border: 2px solid #f1f2f3;
}

.container-list-users {
  position: absolute;
  background-color: grey;
  overflow-y: scroll;
  z-index: 1;
  height: 100px;
  margin-left: 50px;
}

.user-list:hover {
  background-color: rgb(226, 226, 226);
  cursor: pointer;
}

.container-see-more-img {
  width: 161px;
  height: 161px;
  background: rgba(49, 21, 109, 0.8);
  backdrop-filter: blur(5px);
  cursor: pointer;
}

.text-see-more-img {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}

@media screen and (max-width: 450px) {
  .container-see-more-img {
    width: 115px;
    height: 115px;
  }
  .text-see-more-img {
    font-size: 20px;
  }
}
