/* .trengo-title {
  color: var(--secondary-two);
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: "SF Pro Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
} */

.trengo-input {
  border-radius: 8px;
  border: 2px solid var(--border-cards);
  background: var(--White, #FFF);
  padding: 12px;
  color: var(--primary);
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
  width: 100%;
}

.trengo-input:focus-visible {
  border: 2px solid var(--secondary-light);
}

.trengo-input:disabled {
  border-color: var(--disabled-elements);
}

.trengo-input::placeholder {
  color: var(--disabled-elements);
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.trengo-input-label {
  color: var(--secondary);
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
}

.disabled {
  color: var(--disabled-elements);
}