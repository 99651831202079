.carousel-control-prev {
  padding: 5px;
  width: auto !important;
  /* height: 30px; */
  /* left: -120px; */
}

.carousel-control-next {
  padding: 5px;
  width: auto !important;
  height: auto !important;
  /* right: -120px; */
}

.carousel-indicators {
  bottom: -50px;
}
