.textTitle {
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-two);
  margin-bottom: 0px;
}

.textPricing {
  flex-grow: 1;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0px;
  cursor: pointer;
}

.text-and-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myModal {
  width: 320px;
  height: 148px;
}

.titleModal {
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: var(--secondary-two);
  margin: 0px;
  width: 100%;
}

.textModalConsignment {
  color: var(--Dark-Purple-1, #5F35BC);
  font-feature-settings: 'liga'off, 'clig'off;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.inputModal {
  outline: none;
  padding: 11px 10px 12px;
  gap: 10px;
  width: 130px;
  height: 40px;
  background: var(--background-cards);
  border: 2px solid var(--border-cards);
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #616670;
}

.inputModalDisabled {
  outline: none;
  border: none;
  padding: 11px 10px 12px;
  gap: 10px;
  width: 130px;
  height: 40px;
  background: #e9edf3;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
}

.inputModal::placeholder {
  font-size: 14px;
  line-height: 17px;
  color: #b6b5b5;
}

.inputModalDisabled::placeholder {
  font-size: 14px;
  line-height: 17px;
  color: #5c6b86;
}

.dividerInput {
  margin: 10px;
  background: #e1e7ef;
  border-radius: 100px;
  width: 10px;
  height: 2px;
}

.btnCancelarModal {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--secondary-two);
  background: var(--background-cards);
  border: 2px solid var(--secondary-two);
  border-radius: 8px;
  width: 100%;
  height: 40px;
}

.btnPedirRevision {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--primary);
  background: var(--background-cards);
  border: 2px solid var(--primary);
  border-radius: 8px;
  width: 100%;
  height: 40px;
}

.btnCancelRevision {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--red);
  background: var(--background-cards);
  border: 2px solid var(--red);
  border-radius: 8px;
  width: 100%;
  height: 40px;
}

.btnSendPricing {
  width: 100%;
  height: 40px;
  border: none;
  background: var(--primary);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--background-cards);
}

.textHistory {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #899aba;
  margin: 0px;
}

.dividerHistory {
  border-bottom: 2px solid #e1e7ef;
}

.help-icon-modal {
  cursor: pointer;
  color: #ddaf07;
  font-size: 20px;
}

.status-icon {
  width: 20px;
  height: 20px;
}