.container-tables {
  background-color: #fff;
  padding: 20px;
  padding-bottom: 5px;
  border: 1px solid var(--border-cards);
  border-radius: 8px;
  margin-bottom: 20px;
}
.title-label-tabla {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #899aba;
}

.text-head-table {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary);
}

.text-content-table {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: var(--secondary-two);
}

.text-version-current {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}

.scrollTable::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollTable::-webkit-scrollbar:vertical {
  width: 11px;
}

.scrollTable::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.scrollTable::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scrollTable::-webkit-scrollbar-thumb {
  background-color: #899aba;
  border-radius: 10px;
  border: 2px solid #f1f2f3;
}

.scrollTable::-webkit-scrollbar-track {
  background-color: #e1e7ef;
}

.fixed-head {
  position: sticky;
  top: 0;
  background: #fff;
}

.select-order {
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #1b2130;
  border: 1px solid #e1e7ef;
  width: auto;
}

.btn-ordenar {
  width: 74px;
  height: 35px;
  border: none;
  background: var(--primary);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
