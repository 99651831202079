.empty-icon {
  font-size: 70px;
  color: var(--primary);
}

.empty-text {
  font-size: 22px;
  color: dimgrey;
  font-weight: 500;
}

.empty-container {
  display: flex;
  text-align: center;
  align-items: center;
  height: 100%;
}