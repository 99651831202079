.actions-container {
  display: flex;
  flex-wrap: wrap;
}

.status-and-icons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-and-icons {
  cursor: pointer;
  margin-left: 14px;
}

.container-card-deal-layout {
  background: #ffffff;
  border: 1px solid var(--border-cards);
  border-radius: 8px;
  padding: 10px 14px;
  height: 100%;
}

.container-car-col {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.container-row-car-info {
  display: flex;
  align-items: center;
}

.car-info-icon {
  color: var(--primary);
  font-size: 22px;
  margin-right: 6px;
}

.car-info-text {
  color: var(--secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.car-info-text-mobile-size {
  font-size: 12px;
}

.owner-name {
  color: var(--secondary-two);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vertical-divider {
  display: flex;
}

.column-data {
  border-right: 1px solid #ccc;
  padding-right: 0.75rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.columm-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 0.75rem;
}

.offer-type {
  color: var(--secondary-two);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.offer-value {
  color: var(--pricing);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.range-font-size {
  font-size: 16px;
}

.range-font-size-mobile {
  font-size: 12px;
}

.font-size-mobile {
  font-size: 14px;
}

.offer-date {
  color: var(--disabled-elements);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

.pending-offer {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 0px;
}

.eye-icon {
  color: var(--primary);
  font-size: 22px;
}

.navegation-buttons-mobile {
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.navegation-buttons-mobile,
.navegation-buttons {
  display: flex;
  align-items: center;
}

.navegation-button-group {
  font-size: 14px;
  margin-bottom: 8px;
}

.button-group-container {
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.transitioned-section {
  opacity: 1;
  max-height: 1000px;
  /* Establece la altura máxima deseada */
  overflow: hidden;
  transition: all 0.3s ease;
  /* Agrega una animación de 0.3 segundos con una curva de aceleración suave */
}

.hidden-section {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.text-history-deals {
  color: var(--primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
