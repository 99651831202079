.deal-subtitle {
  color: var(--primary);
  margin-bottom: 6px;
  margin-top: 12px;
  font-weight: 600;
  font-size: 14px;
}

.title-discount {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary);
  margin: 0px;
}

.total-discount {
  color: var(--secondary-two);
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  margin-left: 2px;
  margin-top: 1px;
}

.table-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary);
}

.content-table {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: var(--secondary-two);
}

.account-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1b2130;
  margin: 0px;
}

.deal-title {
  color: var(--secondary-two);
  margin-bottom: 6px;
  margin-top: 6px;
  font-weight: 600;
  font-size: 16px;
}

.deal-select {
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-two);
  border: 1px solid var(--border-cards);
  font-weight: 500;
}

select:invalid {
  color: gray;
}

option {
  color: black;
}

.deal-select-title {
  color: #1b2130;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.select-container {
  margin-bottom: 12px;
  margin-top: 8px;
}

.form-select:disabled {
  background-color: #f8f8f8;
  color: rgb(129, 129, 129);
}

select::-ms-expand {
  display: none;
}

.divider {
  background: var(--border-cards);
  height: 1px;
  border-top: var(--border-cards);
}

.optionDisabled {
  /* color: #899aba; */
  color: red;
}

.optionEnabled {
  color: #a9abb2;
  color: red;
}

.btnInformes {
  border: none;
  width: 120px;
  height: 40px;
  background: var(--primary);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--background-cards);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.name-patente {
  margin-bottom: 0px;
  color: var(--secondary);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 20px;
}

.name-patente-mobile {
  margin-bottom: 0px;
  color: var(--secondary);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 20px;
}

.car-data {
  margin-bottom: 0px;
  color: var(--secondary);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.car-data-mobile {
  margin-bottom: 0px;
  color: var(--secondary);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.title-modal-price {
  color: #5f35bc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 8px;
  margin-top: 5px;
}
