#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.drag-and-drop-zone {
  border-radius: 8px;
  border: 2px solid #e8e1ef;
  width: 95%;
  margin-left: 10px;
  height: 100px;
}
