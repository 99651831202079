.rhap_container {

  background-color: #ffffff;
  /* Color de fondo similar al de WhatsApp */
  border-radius: 25px;
  /* Bordes redondeados */
  padding: 8px;
  /* Espaciado interno */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
}

.rhap_controls-section {
  display: flex;
  flex: none;
  justify-content: none;
  align-items: none;
}

.rhap_progress-indicator {
  background: var(--primary);
}

.rhap_play-pause-button {
  background-color: transparent;
  /* Fondo transparente */
  color: var(--primary);
  /* Color del botón similar al de WhatsApp */
  font-size: 30px;
  /* Tamaño del icono */
}

.rhap_progress-filled {

  background-color: var(--secondary-light);

}