.search-input {
  position: relative;
  width: 40px;
  overflow: hidden;
}

.search-input.expanded {
  width: 280px;
  margin-right: 10px;
}

.search-input-text {
  width: 100%;
  border-radius: 5px;
  border: none;
}
.search-input-text::placeholder {
  font-size: 15px;
}

.search-button {
  top: 0;
  right: 0;
  width: 40px;
  overflow: hidden;
}

.search-icon {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}
