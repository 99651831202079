.pricing-table-checkbox {
  margin-right: 4px;
  font-size: 20px;
}

.search-container {
  position: relative;
  width: 300px;

}

.input-search-pricing-view {
  width: 100%;
  padding-right: 20px;
  padding-left: 10px;
  outline: none;
  height: 36px;
  border: 1px solid var(--primary);
  border-radius: 5px;
}

.input-search-pricing-view::placeholder {
  color: #999;
  font-size: 12px;

}

.input-search-pricing-view:focus {
  border: 2px solid var(--primary) !important;

}

.input-search-pricing-view:focus-visible {
  border: 2px solid var(--primary) !important;
}

.search-icon-pricing {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--primary) !important;
  font-size: 18px;
  margin-right: 8px
}

.search-clean-icon {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  cursor: pointer;
  color: grey !important;
}

.container-tipo-cambio {
  border-radius: 8px;
  /* width: 120px; */
  border: 1px solid var(--disabled-elements);
  background-color: white;
  display: flex;
  align-items: center;
}

.tc-text {
  color: var(--secondary-two);
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
}

.tc-ref-text {
  color: var(--Dark-Purple-1, #5F35BC);
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}

.tc-ref-icon {
  color: var(--Dark-Purple-1, #5F35BC);
  font-size: 16px;
}

.card-subtitle {
  color: var(--secondary);
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.card-subtitle-ejem {
  color: var(--Cool-Gray-1, #C8C7CC);
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6pt
}


.pricing-body-modal {
  display: flex;
  flex-direction: column;
}

.input-pricing {
  border-radius: 8px;
  border: 2px solid var(--border-cards);
  background: var(--White, #FFF);
  width: 140px;
  height: 40px;
  padding: 12px 10px 11px 10px;
  outline: none;
  border-radius: 8px;
  overflow: hidden;
  color: var(--Purple, #864BFA);
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input-pricing::placeholder {
  overflow: hidden;
  color: var(--Disabled, #C5C8CC);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-weight: 400;
}

.input-pricing:disabled {
  border: 2px solid var(--disabled-elements);
  /* color: var(--Disabled, #C5C8CC); */
  background-color: #f1f1f1a1;
}

.radio-button-text-pricing {
  color: var(--Ultra-Dark-Purple, #180E31);
  font-family: "SF Pro Display";
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.square-buttons-container {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
}

.square-button {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 12px 10px 11px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 2px solid var(--Card-Stroke, #E8E1EF);
  background: var(--Ultra-Light-Purple, #F7F2FF);
  color: var(--Dark-Purple-2, #31156D);
}

.square-button.selected {
  border: 2px solid var(--Dark-Purple-2, #31156D);
  background: var(--Purple, #864BFA);
  color: var(--White, #FFF);
}

.export-file-button {
  color: var(--Purple, #864BFA);
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
}

.export-file-button-icon {
  font-size: 22px;
  margin-right: 5px;
}

.export-file-button:hover {
  background-color: var(--secondary-ultra-light);
}

.export-file-modal .modal-content {
  max-width: 40%;
  margin: auto;
}

.mobile {
  font-size: 10px;
}

.mobile-icon {
  font-size: 16px;
  margin-right: 2px;
}

.custom-alert .btn-close {
  display: none;
}

.rc-slider-handle {
  border: solid 2px #5F35BC !important;
}

.rc-slider-track, .rc-slider-tracks {
  background-color: #5F35BC !important;
}

.custom-badge {
  background-color: #b4b4b4 !important;

}

.discount-input-text {
  color: var(--Dark-Purple-2, #31156D);
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.max-min-text {
  overflow: hidden;
  color: var(--Dark-Purple-2, #31156D);
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.custom-select-container {
  max-width: 330px;
}