.navbar-text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: var(--background-cards);
  white-space: nowrap;
  overflow: hidden;
  line-height: 29px;
}


.expand-button {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.icon-expand {
  height: 30px;
  width: 30px;
}

.menu-icon, .notif-icon {
  cursor: pointer;
  color: #fff;
  font-size: 24px;
  margin-right: 18px;
}

.notif-icon {
  font-size: 28px;
}

.main-header {
  background-color: var(--secondary-two);
  height: 60px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.logo-auta {
  width: 86px;
  height: 30px;
}

.notification-drawer {
  position: fixed;
  top: 0;
  right: -350px;
  width: 350px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease-in-out;
  z-index: 1001;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.button-more {
  cursor: pointer;
  height: 30px;
  border-radius: 5px;
}

.button-more:hover {
  background-color: var(--secondary-ultra-light);
}

.notification-drawer.open {
  right: 0;
}

.drawer-content {
  overflow-y: scroll;
  height: calc(100vh - 90px);
}

.drawer-header {
  width: 100%;
  padding: 0px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.close-button {
  /* position: absolute; */
  top: 8px;
  right: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 30px;
  color: var(--secondary-two);

}


.notification-title {
  color: var(--primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
  cursor: pointer;
}

.drawer-header:hover {
  border-radius: 8px;
  background-color: var(--secondary-ultra-light);
}

.badge-notif {
  background-color: var(--secondary-ultra-light);
  color: var(--primary);
  font-weight: 600;
  font-size: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  transform: translate(14px, -6px) !important;
  border: solid 1px var(--primary);
}

.notification-item {
  border-left: solid;
  border-left-width: 4px;
  border-left-color: var(--primary);
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--border-cards);
  border-top: solid;
  border-top-width: 1px;
  border-top-color: var(--border-cards);
  cursor: pointer;
  padding: 10px;
  display: flex;
}

.notification-item:hover {
  background-color: var(--secondary-light);
}

.notification-item-header {
  display: flex;
  justify-content: space-between;
}

.accordion-flush .accordion-item .accordion-button {
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
}

.accordion-button:is(.collapsed)::after {
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  box-shadow: none;
}

.circle-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-ultra-light);
  font-size: 16px;
  font-weight: bold;
}

.avatar-container {
  width: 40px;
  height: 100%;
}


.message-title {
  /* color: var(--secondary); */
  font-size: 16px;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;

}

.message-hour {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


.message-subtitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;

}

.message-body {
  font-size: 15px;
  margin-bottom: 0px;
}

.empty-noft {
  text-align: center;
  padding: 8px 0px;
  border-bottom: solid 2px var(--border-cards);
}

.read {
  font-weight: normal
}

.read-container {
  border-left-color: var(--secondary-light);
}