.check-box-table {
  box-shadow: 0 0 0 1px #e1e7ef;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  border-width: 2px;
}

td {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #899aba;
}

th {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #899aba;
}