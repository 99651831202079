.technical-sheet-card {
  width: 100%;
  background: var(--background-cards);
  border: 1px solid #e1e7ef;
  border-radius: 8px;
  padding: 16px 20px 16px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.technical-card-titlel {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--secondary-two);
}

.technical-card-description {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #180e31;
  margin: 0px;
}

.technical-card-value {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary-two);
  margin: 0px;
}

.technical-card-divider {
  width: 100%;
  height: 1px;
  background: #e1e7ef;
  margin: 8px 0px 8px 0px;
}

.technical-card-button {
  width: 100%;
  height: 40px;
  border: 2px solid var(--primary);
  border-radius: 8px;
  background-color: var(--background-cards);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--primary);
}
