.custom-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-two);
  border-color: var(--primary-two);
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  padding: 8px 6px 8px 2px;
  border-radius: 8px;
}

.custom-btn:hover {
  background-color: var(--secondary);
}

.text {
  margin-left: 6px;
  font-size: 16px;
  color: var(--secondary-ultra-light)
}


.accordion-theme-icon, .icon-accordion {
  font-size: 24px;
  color: var(--secondary-ultra-light)
}

a, a:hover {
  text-decoration: none;
  /* Esto eliminará el subrayado del enlace */
  color: inherit;
  /* Esto heredará el color del texto del ancestro */
}