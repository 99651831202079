.card-login {
  padding: 20px;
  background-color: var(--background-cards);
  width: 300px;
  border-radius: 8px;
  border: 1px solid #e1e7ef;
  margin-bottom: 150px;
}

.title-login-card {
  font-size: 30px;
  color: #000000;
}

.button-google {
  background-color: #db4437;
  border: none;
  height: 40px;
  width: 100%;
}

@media (max-width: 480px) {
  .button-google {
    border: none;
    height: 34px;
    /* width: 100%; */
  }

  .title-login-card {
    font-size: 22px;
  }

  .card-login {
    padding: 10px;
    width: 250px;
    margin-bottom: 70px;
  }
}
