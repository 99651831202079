.filter-button {
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
}

.filter-icon-button {
  margin-right: 10px;
}

.filter-menu {
  border: 1px solid var(--border-cards);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 14px;
  padding-right: 18px;
}

.filter-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--secondary-two);
}

.add-filter-button {
  border: none;
  color: var(--blue) !important;
}

.add-filter-button:hover {
  background-color: #fcfcfc;
  border-color: #e1e7ef;
}

.add-filter-button:first-child:active {
  background-color: #e1e7ef;
  border-color: #e1e7ef;
}

.add-filter-icon {
  margin-right: 5px;
}

.dropdown-toggle::after {
  display: none !important;
}

.apply-filter-button {
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 6px;
}

.filter-select {
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #1b2130;
  border: 1px solid var(--border-cards);
  height: 38px;
}

.css-ysi8fv-control:hover {
  border-color: #c3a5fd !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(134, 75, 250, 0.25);
}

.css-155zd60-control {
  border-color: #c3a5fd !important;
  box-shadow: 0 0 0 0.25rem rgba(134, 75, 250, 0.25) !important;
}

.css-155zd60-control:focus {
  border-color: #c3a5fd !important;
}

.icon-close-filter {
  align-self: center;
  margin-left: 5px;
}

.icon-close-filter-container {
  height: 30px;
  width: 30px;
  align-self: center;
  cursor: pointer;
  display: flex;
}

.css-1u9des2-indicatorSeparator {
  visibility: hidden;
}

.css-13cymwt-control {
  border-radius: 8px !important;
}

.css-b62m3t-container {
  width: 100%;
}

.css-t3ipsp-control {
  border-radius: 8px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #1b2130 !important;
  border: 1px solid #e1e7ef !important;
  height: 20px !important;
}

.css-t3ipsp-control:focus {
  border-color: #8fbce2 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgb(30 120 197 / 25%) !important;
}

.css-t3ipsp-control:hover {
  border-color: #86b7fe !important;
}

.css-1jqq78o-placeholder {
  font-size: 14px !important;
  color: #1b2130 !important;
}

.css-13cymwt-control:hover {
  border-color: #e1e7ef !important;
}

.css-tj5bde-Svg {
  color: #1b2130;
  font-size: 14px;
}

.css-3w2yfm-ValueContainer {
  max-height: 35px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.css-1dyz3mf {
  max-height: 35px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.css-3w2yfm-ValueContainer::-webkit-scrollbar {
  display: none;
}

.css-1dyz3mf::-webkit-scrollbar {
  display: none;
}

select option:disabled {
  color: #899aba;
  background: #e9edf3;
}