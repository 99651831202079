.full-page {
  background-color: transparent;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-image: url("../../images/Background_Car.svg");
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auta-logo {
  width: 206px;
  height: 100px;
  background-image: url("../../images/Logotype.svg");
  margin-bottom: 40px;
}

@media (max-width: 480px) {
  .full-page {
    background-image: url("../../images/Background_mobile.svg");
  }

  .auta-logo {
    margin-bottom: 30px;
    width: 165px;
    height: 80px;
    background-image: url("../../images/Logotype_mobile.svg");
  }
}