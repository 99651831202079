#root {
}

html,
body,
#app,
#app > div {
  background-color: var(--background-dashboard);
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("styles/fonts/SF-Pro-Display-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("styles/fonts/SF-Pro-Display-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("styles/fonts/SF-Pro-Display-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("styles/fonts/SF-Pro-Display-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "SF Pro Display", Arial, Verdana, sans-serif;
  margin: 0;
}

.carousel-control-prev-icon {
  background-image: url("../src/images/arrow-slide-left.png");
}

.carousel-control-next-icon {
  background-image: url("../src/images/arrow-slide-right.png");
}

.rdp {
  margin: 0 !important;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrMonth {
  width: 100% !important;
}

.accordion-body {
  padding: 0 !important;
}

.containerAll {
  background: #ffffff;
  border: 1px solid var(--border-cards);
  border-radius: 8px;
  padding: 16px;
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 20px;
}

/* Estilo para ocultar las flechas en un input de tipo número */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Estilo adicional para Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.nav-tabs .nav-link {
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #31156d !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #5f35bc !important;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: var(--primary);
}

.btn:hover {
  background-color: var(--secondary-two);
}
