.trash-icon {
  font-size: 18px;
  color: var(--red);
  align-self: center;
  cursor: pointer;
}

.trash-icon:hover {
  color: var(--red-hover);
}

.input-invite {
  border-radius: 8px;
  border: 2px solid var(--border-cards);
  background: var(--White, #fff);
  width: 100%;
  height: 50px;
  padding: 12px 10px 11px 10px;
  outline: none;
  border-radius: 8px;
  overflow: hidden;
  color: var(--Purple, #864bfa);

  font-feature-settings: "clig"off, "liga"off;
  text-overflow: ellipsis;
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input-invite::placeholder {
  overflow: hidden;
  color: var(--Disabled, #c5c8cc);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 400;
}

.right-align {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.container-icon-data-actions {
  border-radius: 8px;
  background: var(--secondary);
  padding: 5px;
}

.count-actions-data {
  color: white;
}

.divider-actions-data {
  width: 1px;
  height: 18px;
  border-radius: 100px;
  background: var(--secondary-two);
}

.not-capitalize {
  text-transform: none
}

.user-delete {
  margin-top: 20px;
  font-size: 18px;
  color: #31156d
}