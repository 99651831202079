.main-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content-wrapper {
  display: flex;
  flex: 1;
  padding-top: 60px;
  position: relative;

}

.main-content {
  flex: 1;
  overflow-y: auto;
  padding-left: 40px;
}

.floating-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.report-img {
  cursor: pointer;
}

.floating-button-container::before {
  cursor: pointer;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--red-hover);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 100%;
}

.floating-button-container:hover::before {
  opacity: 0.3;
  /* Mostrar la capa al hacer hover */
}

.main-content-mobile {
  flex: 1;
  overflow-y: auto;
}

.sidebar {
  width: 40px;
  height: 90%;
  position: fixed;
  z-index: 1000;
  background-color: var(--secondary-two);
  transition: width 0.3s ease;
  top: 50%;
  transform: translateY(calc(-50% + 30px));
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.sidebar-mobile {
  display: none;
  height: 90%;
  position: fixed;
  z-index: 1000;
  background-color: var(--secondary-two);
  transition: width 0.3s ease;
  top: 50%;
  transform: translateY(calc(-50% + 30px));
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.sidebar-mobile.expanded {
  display: block;
}

.sidebar.expanded {
  width: 220px;
}

.list-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 4px;
}

.list-main-content.expanded {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
}

.icon-list {
  font-size: 16px;
  color: var(--secondary-light);
}

.car-pricing {
  width: 18px;
  margin-right: 8px;
}

.car-pricing-collapse {
  width: 18px;
}

.main-icon-list.expanded {
  font-size: 24px;
  padding-left: 2px;
  color: var(--secondary-ultra-light);
}

.main-icon-list {
  font-size: 24px;
  color: var(--secondary-ultra-light);
}

.company {
  font-size: 20px;
}

.main-icon-company {
  font-size: 20px;
  padding-left: 2px;
  color: var(--secondary-ultra-light);
}

.main-text-company {
  font-size: 14px;
  padding-left: 12px;
  color: var(--secondary-ultra-light);
}

.icon-list.expanded {
  margin-right: 8px;
}

.icon-item {
  margin: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sidebar-divider {
  height: 1px;
  background-color: var(--secondary-light);
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.add-car-button, .menu-items-logout {
  cursor: pointer;
  color: var(--secondary-ultra-light);
  width: 100%;
  padding: 8px 6px 8px 2px;
  border-radius: 8px;
}

.menu-items-logout:hover, .item-view:hover, .add-car-button:hover {
  background-color: var(--secondary);
  color: #fff;
}

.accordion-theme-icon-logout {
  font-size: 20px;
  margin-right: 16px;
}

.item-view {
  color: var(--secondary-ultra-light);
  width: 100%;
  cursor: pointer;
  padding: 8px 6px 8px 14px;
  border-radius: 8px;
  font-size: 14px;
  margin: 4px 0px;
}


.item-active {
  background-color: var(--secondary);
  color: #fff;
}

.user-date-sidebar {
  margin: 4px;
  color: #fff;
  font-feature-settings: 'clig'off, 'liga'off;
  font-size: 15px;
}

.user-container {
  display: flex;
  align-items: center;
}