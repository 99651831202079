/* table,
th,
td {
  border: 1px solid var(--border-cards) !important;
} */

.textHead {
  color: var(--secondary-two);
  font-weight: 500;
  font-size: 14px;
  border-bottom: 2px solid var(--primary) !important;
}

.icon-container-button {
  background: #899aba;
  border-radius: 6px;
  padding: 2px;
  border: none;
}

.table-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--secondary);
}

.table-body {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: var(--secondary-two);
}

.icon-container-table {
  width: 20px;
  height: 20px;
  background: #899aba;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}