.label-input {
  color: var(--secondary);
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  display: block;
  transition: color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
  box-sizing: border-box;
}

.label-input-check {
  color: #1b2130;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  display: block;
  transition: color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
  box-sizing: border-box;
}

.label-input-check::first-letter {
  text-transform: capitalize;
}

.input-deal {
  border: 1px solid #e1e7ef;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  color: var(--secondary-two);
  padding-left: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: 500;
}

.input-deal:hover,
.input-deal:focus,
.input-deal {
  outline: none;
  border-color: #e1e7ef;
}

.label-input,
.input-deal:focus {
  color: var(--secondary);
}

.input-deal:disabled {
  color: rgb(129, 129, 129);
}
