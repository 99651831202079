.menu {
  position: initial;
  min-width: 250px;
}

.menu-text {
  margin: auto;
  width: 100%;
  font-size: 14px;
}

.menu-item {
  padding-left: 22px;
  height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: left;
}

.menu-item-icon {
  color: var(--primary);
  font-size: 18px;
  float: right;
  margin-right: 20px;
}

.menu-link {
  text-decoration: none;
  color: #180e31;
  font-weight: 500;
}

.menu-item:hover {
  background-color: #f8f8f8;
  color: var(--primary);
}

.containerPricing {
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  border: solid 1px #fff;
}

.divider-pricing {
  border-bottom: 1px solid #e1e7ef;
  margin-top: 8px;
  margin-bottom: 8px;
}

.containerPricing:hover {
  background-color: #f8f8f8;
  border: solid 1px var(--secondary-light);
}

.dropdown-menu {
  margin-right: auto;
}

.accordion-button {
  padding-left: 22px;
  height: 40px;
  border: none;
  font-size: 14px;
}

.accordion-item {
  border: none;
}

.accordion-button:not(.collapsed) {
  background-color: #fff;
}

.accordion-button:focus {
  border: none !important;
  box-shadow: none;
}

.offcanvas-body {
  padding: 0px;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.offcanvas-header {
  padding-bottom: 10px;
}

.item-menu-user {
  margin: 0px;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
}

.item-menu-user:hover {
  background-color: rgba(217, 196, 255, 0.3);
}
